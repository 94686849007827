import cx from 'classnames'
import { motion } from 'framer-motion'
import { FC, ReactNode } from 'react'
import { Spinner } from '../Spinner'

interface Props {
  children?: ReactNode
  className?: string
  checked: boolean
  loading?: boolean
  onChange: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
}

const Switch: FC<Props> = ({
  checked,
  children,
  className,
  loading,
  onChange,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx(
        !children && 'inline-block',
        !!children && 'inline-flex items-center gap-3 text-sm',
        className,
      )}
    >
      <button
        role='switch'
        aria-checked={checked}
        onClick={e => onChange(!checked, e)}
        className={cx(
          'base-transition relative m-0 p-0 h-5 min-w-[2rem] rounded-full',
          checked ? 'bg-green-900' : 'bg-separation-900',
        )}
      >
        <motion.div
          animate={{ left: !checked ? '2px' : 'calc(100% - 18px)' }}
          transition={{ type: 'tween', duration: 0.2 }}
          className='absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-white'
        >
          {loading && <Spinner size='2xsmall' />}
        </motion.div>
      </button>
      {children}
    </div>
  )
}

export { Switch }
